import baseEditForm from "formiojs/components/_classes/component/Component.form";

const settings = (...extend) => {
    return baseEditForm(
        [{ 
        key: "data",
        components: [
            {
                type: 'datagrid',
                input: true,
                label: 'Mentions',
                key: 'mentions',
                reorder: false,
                components: [
                    {
                        label: "Feed",
                        key: "feed",
                        type: "textarea",
                        input: true,
                        tooltip: "Feed (json array)",
                        required: false,
                        value:""
                    },                    
                    {
                        label: "Feed Request",
                        key: "feedRequest",
                        type: "textarea",
                        input: true,
                        tooltip: "Javascript to get feed for this mention (use this or feed)",
                        required: false,
                        value:""
                    },
                    {
                        label: "Marker",
                        key: "marker",
                        type: "textfield",
                        input: true,
                        tooltip: "Character representing this marker",
                        required: false,
                        value:""
                    },           
                    {
                        label: "Minimum Chars",
                        key: "minChars",
                        type: "number",
                        input: true,
                        tooltip: "Minimum characters to use before checking feed",
                        required: false,
                        value:""
                    },                                  
                    {
                        label: "Item Template",
                        key: "itemTemplate",
                        type: "textarea",
                        input: true,
                        tooltip: "Template for display of items in pick list (HTML)",
                        required: false,
                        value:""
                    },                     
                    {
                        label: "Output Template",
                        key: "outputTemplate",
                        type: "textarea",
                        input: true,
                        tooltip: "Template for output of item into content (HTML)",
                        required: false,
                        value:""
                    },  
                ]
            }
        ]
        }
        , ...extend]
    );
};

export default settings;