import { createMuiTheme } from "@material-ui/core";

// Colours
const teal = '#0B6970';
const grey = '#E0E0E0';
const greyHover = "#BCBCBC"
const white = '#fff';
const black = '#000';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: teal,
        }
    },

    overrides: {
        MuiButton: {
            containedPrimary: {
                '&:hover': {
                    color: white
                }
            },

            containedSecondary: {
                backgroundColor: white,
                color: teal,
                padding: '5px 15px',
                border: `1px solid ${teal}`,
                '&:hover': {
                    color: white,
                    backgroundColor: teal,
                }
            },

            contained: { //tertiary
                backgroundColor: grey,
                color: black,
                '&:hover': {
                    backgroundColor: greyHover
                }
            }

        },

        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                    color: teal
                }
            },

            colorPrimary: {
                color: teal
            },
        },

        MuiLink: {
            root: {
                color: '#757575'
            }
        },

        MuiTypography: {
            colorTextPrimary: {
                color: teal,
                fontWeight: 400
            }
        },
    },
});

export default theme;