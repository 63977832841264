import { Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';

import useThrowAsyncError from '../../../../hooks/asyncErrorHook';
import useSessionState from '../../../../hooks/useSessionState';
import { getNestedProperty, urlBuilder } from '../../../../util/helpers';
import { getSubComponents, isNotFormSubmission } from '../../formUtils';
import { dataTypes } from '../constants';
import { CKEditor } from 'ckeditor4-react';

const json = (function(raw) {
    try {
        return JSON.parse(raw);
    } catch (err) {
        return [];
    }
});

const isEmptyStr = (str) => (!str?.length);

export default function Renderer({ component, options, onChange }) {

    const [data, setData] = useState("");

    const inputHandler = (event) => {
        options.root.submission.data[component.key] = event.editor.getData();
        onChange(event.editor.getData());
    };    

    if (!component) return null;

    //Wait for the form submission data to be ready
    options.root.submissionReady.then(onSubmissionReady);

    if (!data) return null;

    var mentions = component.mentions ?         
    component.mentions.map( (m) => ({
        feed : !isEmptyStr(m.feedRequest) ? new Function(['options', 'callback'], m.feedRequest) : json(m.feed),
        marker: isEmptyStr(m.marker) ? '@': m.marker,
        minChars: m.minChars ?? 0,
        itemTemplate: isEmptyStr(m.itemTemplate) ? undefined : m.itemTemplate,
        outputTemplate: isEmptyStr(m.outputTemplate) ? undefined : m.outputTemplate
    })) : [];
        
    var configObject = { "extraPlugins" : "mentions", "mentions": mentions };
    const editorComponent = 
        <CKEditor
        config={configObject}
        initData={data}
        onChange={inputHandler} />;

    return editorComponent

    function onSubmissionReady() {
        setData(options.root.submission.data[component.key]);
    }

}
